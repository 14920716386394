import React from "react"
import { Link, graphql, navigate } from "gatsby"

import Lodash from "lodash"

import Autocomplete from "@material-ui/lab/Autocomplete"
import Box from "@material-ui/core/Box"
import TextField from "@material-ui/core/TextField"
import ToolTip from "@material-ui/core/Tooltip"

import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardContent from "@material-ui/core/CardContent"

import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Tool from "../components/helpers/tool"

export const query = graphql`
  query AllToolsQuery {
    allToolsJson {
      edges {
        node {
          path
          title
          description
        }
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`

class IndexPage extends React.Component<{ data: any }> {
  searchBar = () => {
    const { data } = this.props

    return (
      <Autocomplete
        blurOnSelect
        disableClearable
        options={data.allToolsJson.edges}
        getOptionLabel={edge => edge.node.title}
        onChange={(event, value) =>
          navigate(Tool.rootPath + value.node.path + "/")
        }
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            label={`Search in ${data.allToolsJson.edges.length} tools`}
            variant="outlined"
            placeholder={"Choose one tool to open"}
          />
        )}
      />
    )
  }

  toolsGridWidget = () => {
    const { data } = this.props

    const toolsGrid = Lodash.groupBy(
      data.allToolsJson.edges,
      edge => edge.node.path.split("/")[0]
    )

    return (
      <Grid container spacing={2}>
        {Object.entries(toolsGrid).map(entry => (
          <Grid item xs={12} sm={6} md={3} key={entry[0]}>
            <Card>
              <CardHeader title={Lodash.capitalize(entry[0])}></CardHeader>
              <CardContent>
                <List component="nav" aria-label="tools" dense>
                  {entry[1].map(edge => (
                    <ListItem key={edge.node.path} button dense>
                      <ToolTip title={edge.node.description} enterDelay={500}>
                        <Link to={Tool.rootPath + edge.node.path + "/"}>
                          <ListItemText
                            primary={edge.node.title}
                          ></ListItemText>
                        </Link>
                      </ToolTip>
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    )
  }

  render() {
    const { data } = this.props

    return (
      <Layout>
        <SEO title="Home" />

        <Box mt={4} mb={2}>
          <Box display="flex" fontSize="h2.fontSize" justifyContent="center">
            {data.site.siteMetadata.title}
          </Box>

          <Box display="flex" fontSize="body1.fontSize" justifyContent="center">
            {data.site.siteMetadata.description}
          </Box>
        </Box>

        <Box width={1} m={4} display="flex" justifyContent="center">
          <Box width={3 / 4}>{this.searchBar()}</Box>
        </Box>

        {this.toolsGridWidget()}
      </Layout>
    )
  }
}

export default IndexPage
